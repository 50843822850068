<template>
    <div>
      <Form ref="form" class="editForm" inline :model="form" :rules="ruleValidate" :label-width="80">
        <FormItem label="手机号" prop="phone" style="width: 100%">
          <Input type="number" v-model="form.phone" placeholder="手机号"></Input>
        </FormItem>
        <FormItem label="验证码" prop="code" style="width: 100%">
          <Input v-model="form.code" :maxlength="4" show-word-limit style="width: calc(100% - 110px);margin-right: 7px;" placeholder="验证码"></Input>
          <Button type="primary" :disabled="codeSendFlag" :loading="sendLoading" style="width: 101px;" @click="sendCode">{{ codeSendFlag ? (time + '秒') : '发送验证码' }}</Button>
        </FormItem>
        <FormItem label="新密码" prop="newPass" style="width: 100%">
          <Input type="password" v-model="form.newPass" placeholder="请输入新密码"></Input>
        </FormItem>
        <FormItem label="重复密码" prop="rePass" style="width: 100%">
          <Input type="password" v-model="form.rePass" placeholder="请输入新密码"></Input>
        </FormItem>
      </Form>
      <div class="modalEditBtnArea">
        <Button type="primary" @click="ok('/auth/ForgetPass')">确认提交</Button>
        <Button @click="close">取消</Button>
      </div>
    </div>
  </template>

  <script>
  import edit from '@/mixins/edit'
  import {sendSmsCode} from '../../api/common'

  export default {
    name: '',
    mixins: [edit],
    data() {
      return {
        form: {
          phone: '',
          code: '',
          newPass: '',
          rePass: '',
        },
        sendLoading: false,
        codeSendFlag: false,
        time: 90,
        ruleValidate: {
          phone: [
            {required: true, message: '请填写手机号', trigger: 'blur,change'}
          ],
          code: [
            {required: true, message: '请填写验证码', trigger: 'blur,change'}
          ],
          newPass: [
            {required: true, trigger: 'blur', validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('新密码不能为空!'));
              } else {
                const reg = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d).{7,}$/);
                if (reg.test(this.form.newPass)) {
                  callback()
                } else {
                  callback(new Error('密码长度必须大于6位，且包含数字和字母!'));
                }
              }
            }}
          ],
          rePass: [
            {required: true, trigger: 'blur', validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码!'));
              } else if (value !== this.form.newPass) {
                callback(new Error('两次密码输入不一致，请确认!'));
              } else {
                callback();
              }
            }}
          ]
        },
      }
    },

    mounted() {},

    methods: {
      sendCode() {
        if (!this.form.phone || this.form.phone.length !== 11) {
          this.$Notice.error({title: '提示', desc: '请输入正确的手机号！'});
          return
        }
        this.codeSendFlag = true;
        this.sendLoading = true;
        sendSmsCode({phone: this.form.phone}).then(res => {
          if (res.code === 200) {
            let timer = window.setInterval(() => {
              this.time--;
              if (this.time === 0) {
                this.time = 90;
                this.codeSendFlag = false;
                window.clearInterval(timer);
              }
            }, 1000)
          } else {
              this.codeSendFlag = false;
              this.sendLoading = false;
            }
        }).finally(() => {
           this.sendLoading = false;
        })
        }
    }
  }
  </script>
